import $ from "jquery";

export default class FiltersPopin {
  static activeClass = 'filter__popin--active';
  
  constructor(button, popin) {
    this.button = button;
    this.popin = popin;

    this.button.on("click", this.toggle);
    $(document).on("click", this.handleExternalClick);
  }

  toggle = () => {
    this.popin.toggleClass(FiltersPopin.activeClass);
  }
  
  hide = () => {
    this.popin.removeClass(FiltersPopin.activeClass);
  }

  handleExternalClick = (e) => {
    const isInPopin = $(e.target).closest(this.popin).length > 0;
    const isOnButton = $(e.target).closest(this.button).length > 0;

    if (!isInPopin && !isOnButton) {
      this.hide();
    }
  }
}

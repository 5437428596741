import $ from "jquery";
import MicroModal from "micromodal";
import { modalOptions, clearModal } from "./utils/modal";
import customSelect from "./lib/customSelect";
import dateInputMask from "./utils/dateInputMask";
import {
  EVENT_STUDENT_UPDATED,
  EVENT_STUDENT_CREATED,
  EVENT_STUDENT_DRAFT_CREATED,
} from "./events";
import openConfirmCloseModal from "./openConfirmCloseModal";
import openConfirmValidationModal from "./openConfirmValidationModal";
import moment from "moment";
import handleFormErrorsOnSubmit from "./utils/handleFormErrorsOnSubmit";

export default class StudentForm {
  form;
  isDraft = false;
  saveDraftButton;
  saveButton;
  mustConfirmClosure = true;
  requestOriginSelect;
  onOpen;
  onClose;
  studentActionStatus;

  constructor() {
    this.loadTemplate();

    dateInputMask();

    this.saveDraftButton = this.form.find('[name="student[save_draft]"]');
    this.saveButton = this.form.find('[name="student[save]"]');

    this.saveDraftButton.on("click", () => {
      this.isDraft = true;
      this.form.attr("novalidate", "");
    });

    this.saveButton.on("click", () => {
      this.isDraft = false;
      this.form.removeAttr("novalidate");
    });

    this.requestOriginSelect = this.form.find("#student_requestOrigin");

    this.form.on("submit", this.handleSubmit);
    this.form.on("reset", this.close);

    this.handleRequestOriginChange();

    const studyLevelField = this.form.find('[name="student[studylevel]"]');
    studyLevelField.prop("disabled", true);
    this.form.append(
      `<input 
        type="hidden"
        name="student[studylevel]"
        value="${studyLevelField.val()}"
      />`
    );

    this.handleDiplomaChange();

    this.requestOriginSelect.on("change", this.handleRequestOriginChange);
    this.onChangeChangeSchool();
  }

  handleRequestOriginChange = () => {
    const value = this.requestOriginSelect.val();
    const otherOptions = this.requestOriginSelect.find(
      `[value="${value}"][data-code='request_origin_autre']`
    );

    if (otherOptions.length) {
      this.form.find(".form_dependent_field").show();
    } else {
      this.form.find(".form_dependent_field").hide();
    }
  };

  handleDiplomaChange = () => {
    const diplomaSelect = this.form.find('[name="student[lastDiploma]"]');

    diplomaSelect.on("change", () => {
      const lvlId = window.diplomasStudyLevelsMap[diplomaSelect.val()];
      const studyLevelSelect = this.form.find(
        'select[name="student[studylevel]"]'
      );
      const studyLevelHidden = this.form.find(
        'input[name="student[studylevel]"]'
      );
      studyLevelHidden.val(lvlId);
      studyLevelSelect.get(0).customSelect.value = lvlId;
    });
  };

  loadTemplate = () => {
    const template = $('[data-modal-template="sheet"]').html();
    const modal = $("#zoom [data-modal-content]");
    modal.append(template);

    this.form = modal.find("form[name='sheet']");
  };

  open = () => {
    MicroModal.show("zoom", {
      ...modalOptions,
      onClose: el => {
        clearModal($(el));
      },
      onShow: () => {
        customSelect(this.form.find("select"));
        this.checkBirthDate();
        this.checkDateMeetAndFirstContact();

        if ("function" === typeof this.onOpen) {
          this.onOpen();
        }
      },
    });
  };

  hydrate = data => {
    this.mustConfirmClosure = false;

    Object.keys(data.student).forEach(key => {
      const value = data.student[key];
      const element = this.form.find(`[name="${key}"]`);
      element.val(value);
    });

    this.studentActionStatus = data.student["student[studentAction][status]"];

    if (this.studentActionStatus >= 3) {
      $('[name="student[studentAction][contactedAt]"]').attr("readonly", true);
      $('[name="student[studentAction][meetAt]"]').attr("readonly", true);
    }

    if (this.studentActionStatus > 1) {
      this.form.find('[name="student[save_draft]"]').hide();
      $("#zoom [data-modal-content]")
        .find("[data-student-sheet-status]")
        .text("Validée");
    }

    const readonlyFields = this.form.find(
      [
        '[name="student[email]"]',
        '[name="student[firstname]"]',
        '[name="student[lastname]"]',
      ].join(",")
    );

    const disabledFields = this.form.find([].join(","));

    if (data.student["student[waltId]"]) {
      readonlyFields.prop("readonly", true);
      disabledFields.prop("disabled", true);

      disabledFields.each((index, item) => {
        this.form.append(
          `<input data-type="disabled-select-replacer" type="hidden" name="${item.name}" value="${item.value}" />`
        );
      });
    } else {
      readonlyFields.prop("readonly", false);
      disabledFields.prop("disabled", false);
      this.form.find('[data-type="disabled-select-replacer"]').remove();
    }

    // désactivation du champ StudyLevel car le niveau est maintenant
    // setté automatiquement en fonction du diplôme
    const studyLevelField = this.form.find('[name="student[studylevel]"]');
    studyLevelField.prop("disabled", true);
    this.form.append(
      `<input 
        type="hidden"
        name="student[studylevel]"
        value="${studyLevelField.val()}"
      />`
    );

    this.handleRequestOriginChange();
    this.handleDiplomaChange();
  };

  checkDateMeetAndFirstContact = () => {
    const checkDates = (meetAtField, contactedAtField) => {
      if (!meetAtField.value && contactedAtField.value) return;

      const meetAt = moment(meetAtField.value, "DD/MM/YYYY");
      const contactedAt = moment(contactedAtField.value, "DD/MM/YYYY");

      if (!meetAt.isValid || !contactedAt.isValid) return;

      if (meetAt.isSameOrAfter(contactedAt)) {
        meetAtField.setCustomValidity("");
        contactedAtField.setCustomValidity("");
      } else {
        meetAtField.setCustomValidity(
          "La date de la rencontre doit être postérieure à celle du 1er contact."
        );
        contactedAtField.setCustomValidity(
          "La date du 1er contact doit être antérieure à celle de la rencontre."
        );
      }
    };

    const meetAtField = $('[name="student[studentAction][meetAt]"]', this.form);
    const contactedAtField = $(
      '[name="student[studentAction][contactedAt]"]',
      this.form
    );

    checkDates(meetAtField[0], contactedAtField[0]);
    meetAtField.on("change", e => checkDates(e.target, contactedAtField[0]));
    contactedAtField.on("change", e => checkDates(meetAtField[0], e.target));
  };

  checkBirthDate = () => {
    if (this.isDraft) {
      $('[data-depends="birthday"]', this.form).removeAttr("required");
      return;
    }

    const checkAge = field => {
      if (!field.value) return;
      const birthDate = moment(field.value, "DD/MM/YYYY");
      if (!birthDate.isValid) {
        $(field).addClass("has-error");
        return;
      }

      const age = moment().diff(birthDate, "years", false);

      field.setCustomValidity("");
      if (age < 14 || age >= 60) {
        field.setCustomValidity(
          "L'âge du contact n'est pas compatible avec une inscription au CRM"
        );
      } else if (age < 18) {
        $('[data-depends="birthday"]', this.form).attr("required", "required");
      } else {
        $('[data-depends="birthday"]', this.form).removeAttr("required");
      }
    };

    const field = $('[name="student[birthDate]"]', this.form);
    checkAge(field[0]);
    field.on("change", e => checkAge(e.target));
  };

  handleSubmit = e => {
    e.preventDefault();

    const data = this.form.serializeArray();
    const formData = {};
    data.forEach(item => (formData[item.name] = item.value));

    const isCreating = "" === formData["student[id]"];

    if (
      (!this.studentActionStatus || 1 === this.studentActionStatus) &&
      !this.isDraft
    ) {
      openConfirmValidationModal();
      $("#status [data-modal-content]")
        .find('[data-action="validate-sheet"]')
        .on("click", () => {
          MicroModal.close("status");
          this.save(formData, isCreating);
        });
    } else {
      this.save(formData, isCreating);
    }
  };

  save = (formData, isCreating) => {
    const url = this.form.attr("action") + `?isDraft=${this.isDraft ? 1 : 0}`;
    this.mustConfirmClosure = false;

    $("body").addClass("loader");

    $.post(url, formData)
      .then(data => {
        $(".flashes-error").fadeOut();
        $("body").removeClass("loader");

        if (data.success) {
          $(window).trigger(
            isCreating
              ? this.isDraft
                ? EVENT_STUDENT_DRAFT_CREATED
                : EVENT_STUDENT_CREATED
              : EVENT_STUDENT_UPDATED,
            parseInt(formData["student[id]"], 10)
          );

          this.close();
        }
      })
      .fail(err => {
        $("body").removeClass("loader");
        handleFormErrorsOnSubmit(err);
      });
  };

  close = (e, callback = () => {}) => {
    if (e) {
      e.preventDefault();
    }

    const closeFormModal = () => {
      MicroModal.close("zoom");
      $(".modal__zoom")
        .children()
        .remove();
      callback();
    };

    if (this.mustConfirmClosure) {
      openConfirmCloseModal();
      $("[close-confirm-button]").on("click", function(e) {
        e.preventDefault();
        closeFormModal();
        MicroModal.close("status");
      });
    } else {
      closeFormModal();
    }
  };

  onChangeChangeSchool() {
    const that = this;
    $("[data-change-school]").on("click", function(e) {
      that.close(e, () => $("[data-open-school-choice]").click());
    });
  }
}

// polyfills for IE
import "core-js/es/object/assign";
import "core-js/es/promise";
import "core-js/es/string/includes";
import "core-js/es/array/includes";
import "core-js/es/array/from";
import "core-js/es/array/for-each";
import "core-js/es/object/values";
// import "core-js/es/array";
import "nodelist-foreach-polyfill";

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }

      el = el.parentElement || el.parentNode;
    } while (el !== null && 1 === el.nodeType);

    return null;
  };
}

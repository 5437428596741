import $ from "jquery";

const group1 = [
  "student_situation_result_formation_en_cours",
  "student_situation_result_rupture_a_linitiative_du_jeune",
  "student_situation_result_rupture_a_linitiative_de_lentreprise",
  "student_situation_result_en_recherche_dentreprise",
  "student_situation_result_retour_en_formation_initiale",
  "student_situation_result_ne_repond_plus_aux_sollicitations",
];
const group2 = [
  "student_situation_result_contrat_en_cours",
  "student_situation_result_recherche_une_alternance_en_parallele",
  "student_situation_result_recherche_une_formation_initiale_en_parallele",
  "student_situation_result_recherche_un_contrat_durable_ou_une_autre_entreprise",
  "student_situation_result_ne_repond_plus_aux_sollicitations",
];
const group3 = [
  "student_situation_result_recherche_une_alternance",
  "student_situation_result_recherche_une_formation_initiale",
  "student_situation_result_recherche_demploi",
  "student_situation_result_ne_repond_plus_aux_sollicitations",
];

export default function handleSituationSelectChange(
  event,
  allOptions,
  resultSelect
) {
  const select = $(event.target);
  const csSel = resultSelect.parent().get(0).customSelect;
  const value = select.val();
  const code = select.find(`option[value="${value}"]`).data("code");

  csSel.empty();

  const newOptions = [];
  switch (code) {
    case "student_stuation_formation_en_contrat_dapprentissage":
    case "student_stuation_formation_en_contrat_de_professionnalisation":
    case "student_stuation_formation_autre_qualternance":
      allOptions.each((index, item) => {
        if (group1.indexOf($(item).data("code")) >= 0) {
          newOptions.push(item);
        }
      });
      break;

    case "student_stuation_cdi":
    case "student_stuation_cdd_de_de_6_mois":
    case "student_stuation_cdd_de_6_mois_et":
    case "student_stuation_interim":
      allOptions.each((index, item) => {
        if (group2.indexOf($(item).data("code")) >= 0) {
          newOptions.push(item);
        }
      });
      break;

    case "student_stuation_sans_emploi":
    case "student_stuation_autres":
      allOptions.each((index, item) => {
        if (group3.indexOf($(item).data("code")) >= 0) {
          newOptions.push(item);
        }
      });
      break;
  }

  csSel.append(newOptions);
}

import $ from "jquery";
// import customSelect from "custom-select";
import customSelect from "./lib/customSelect";
import MicroModal from "micromodal";
import stringsCollectionField from "./stringsCollectionField";
import { modalOptions, clearModal } from "./utils/modal";
import deleteModal from "./deleteModal";
import {
  EVENT_STUDENT_UPDATED,
  EVENT_STATUS_UPDATED,
  EVENT_STUDENT_DELETED,
} from "./events";
import StudentForm from "./StudentForm";
import shareModal from "./shareModal";
import { openFlash } from "./utils/flashMessage";
import handleFormErrorsOnSubmit from "./utils/handleFormErrorsOnSubmit";
import companiesCollectionField from "./companiesCollectionField";
import moment from "moment";
import handleSituationSelectChange from "./handleSituationSelectChange";

export default class StudentRow {
  container = null;
  studentId = null;

  constructor(container, studentsList) {
    this.container = container;
    this.studentsList = studentsList;
    this.studentId = container.data("student-id");

    this.init();

    $(window).on(EVENT_STUDENT_UPDATED, (e, studentId) => {
      if (this.studentId !== studentId) {
        return;
      }

      this.refresh();

      // update le résumé s'il est ouvert
      // sinon on le vide pour qu'il soit rechargé la prochaine fois
      // this.isOpen() ? this.loadSummary() : this.clearSummary();
    });
  }

  init = () => {
    this.studentActionId = this.container.data("student-action-id");
    this.studentMetAt = moment(
      this.container.data("student-met-at"),
      "YYYY-MM-DD"
    );
    this.studentIntegratedAt = moment(
      this.container.data("student-integrated-at"),
      "YYYY-MM-DD"
    );

    this.editModalButton = this.container.find("[data-action='modify-sheet']");
    this.shareModalButton = this.container.find("[data-action='share-sheet']");
    this.spinner = this.container.find(".student__sheet__spinner");
    this.statusCustomSelect = customSelect(this.container.find("select"))[0];
    this.container.find("[data-button-more]").on("click", this.openSummary);
    this.summaryContainer = this.container.find(".student__sheet__summary");
    this.currentStatusSelectVal = null;
    this.statusSelect = this.container.find("[status-select-dropdown]");
    this.row = this.container.find(".student__sheet__row");
    this.initStatusModal();
    this.editModalButton.on("click", this.openEditModal);
    this.shareModalButton.on("click", this.openShareModal);
  };

  refresh = () => {
    this.showSpinner();
    $.get(`/me/student-row/${this.studentId}.json`).then(
      ({ template, student }) => {
        this.hideSpinner();

        this.container.attr("data-student-email", student.email);
        // this.container.html(template);
        this.container.after(template);
        const oldContainer = this.container;
        this.container = this.container.next();
        oldContainer.remove();
        this.init();
      }
    );
  };

  showSpinner = () => {
    this.spinner.show();
  };

  hideSpinner = () => {
    this.spinner.hide();
  };

  openDeleteModal = () => {
    const studentId = this.container.data("student-id");
    deleteModal();
    $("[data-delete-button]").on("click", function() {
      const url = $(this)
        .attr("data-url")
        .replace("studentId", studentId);
      $.get(url).then(data => {
        if (data.success) {
          MicroModal.close("status");
          $(".modal__zoom")
            .children()
            .remove();
          $(window).trigger(EVENT_STUDENT_DELETED);
        }
      });
    });
  };

  openShareModal = () => {
    const studentId = this.container.data("student-id");
    const studentMail = this.container.data("student-email");
    shareModal();
    $("[data-role='share-url']").append(studentMail);

    $("[data-share-button]").on("click", function() {
      const url = $(this)
        .attr("data-url")
        .replace("studentId", studentId);
      $.post(url).then(data => {
        if (data.success) {
          MicroModal.close("status");
          $(".modal__zoom")
            .children()
            .remove();
          openFlash("share-data-message");
        }
      });
    });
  };

  fillPopinForm = data => {
    Object.keys(data).forEach(key => {
      const selector = `[name="${key}"]`;
      const html = $("#status [data-modal-content]");
      const element = html.find(selector);
      const value = data[key];

      if (key.includes("__name__")) {
        Object.keys(value).forEach(key => {
          const item = value[key];
          element.val(item);
          element.next().trigger("click");
        });
      } else {
        element.val(value);
      }
    });
  };

  openProjectOrientationModal = () => {
    $.get(`/me/student/${this.studentId}/proposed-orientations.json`).then(
      data => {
        const template = $("[data-modal-template='project-orientation']");
        MicroModal.show("status", {
          ...modalOptions,
          onClose: el => {
            clearModal($(el));
          },
        });

        $("#status [data-modal-content]").html(template.html());
        const form = $("#status [data-modal-content]").find("form");

        const studentId = this.container.data("student-id");
        $("[name='proposed_orientation[studentId]']", form).val(studentId);

        stringsCollectionField();
        this.fillPopinForm(data);

        form.on("submit", e => {
          e.preventDefault();
          const data = form.serializeArray();
          const formData = {};

          data.forEach(item => (formData[item.name] = item.value));

          const url = form.attr("action");

          $.post(url, formData).then(data => {
            if (data.success) {
              MicroModal.close("status");
              $(".modal__zoom")
                .children()
                .remove();
              this.loadSummary();
            } else {
              $(".flashes-error").fadeIn(500);
            }
          });
        });

        form.on("reset", () => {
          $("body").removeClass("modal-is-visible");
          $("#status").removeClass("is-open");
          $(".modal__zoom")
            .children()
            .remove();
          MicroModal.close();
        });
      }
    );
  };

  openIntegratedTrainingModal = () => {
    $.get(`/me/student/${this.studentId}/integrated-trainings.json`).then(
      data => {
        const template = $("[data-modal-template='integrated-training']");
        MicroModal.show("status", {
          ...modalOptions,
          onClose: el => {
            clearModal($(el));
          },
        });

        $("#status [data-modal-content]").html(template.html());
        const form = $("#status [data-modal-content]").find("form");

        const studentId = this.container.data("student-id");
        $("[name='integrated_training[studentId]']", form).val(studentId);
        stringsCollectionField();
        this.fillPopinForm(data);

        form.on("submit", e => {
          e.preventDefault();
          const data = form.serializeArray();
          const formData = {};

          data.forEach(item => (formData[item.name] = item.value));

          const url = form.attr("action");

          $.post(url, formData).then(data => {
            if (data.success) {
              MicroModal.close("status");
              $(".modal__zoom")
                .children()
                .remove();
              this.loadSummary();
            } else {
              $(".flashes-error").fadeIn(500);
            }
          });
        });

        form.on("reset", () => {
          $("body").removeClass("modal-is-visible");
          $("#status").removeClass("is-open");
          $(".modal__zoom")
            .children()
            .remove();
          MicroModal.close("status");
        });
      }
    );
  };

  openPMSMPModal = () => {
    const template = $("[data-modal-template='add-pmsmp']");
    MicroModal.show("status", {
      ...modalOptions,
      onClose: el => {
        clearModal($(el));
      },
    });
    $("#status [data-modal-content]").html(template.html());
    const form = $("#status [data-modal-content]").find("form");
    customSelect(form.find("select"));

    const studentId = this.container.data("student-id");
    $("[name='pmsmp[studentId]']", form).val(studentId);

    const pmsmpResult = form.find("#pmsmp_result");
    form.find("[condition-section]").hide();

    const updatePMSMPModal = () => {
      const fieldValue = pmsmpResult.val();
      const fieldCode = pmsmpResult
        .find(`option[value="${fieldValue}"]`)
        .data("code");
      switch (fieldCode) {
        case "pmsmp_result_autre":
          $("[condition-section]").show();
          break;
        default:
          $("[condition-section]").hide();
          break;
      }
    };
    updatePMSMPModal();
    pmsmpResult.on("change", updatePMSMPModal);

    form.on("submit", e => {
      e.preventDefault();
      const data = form.serializeArray();
      const formData = {};

      data.forEach(item => (formData[item.name] = item.value));

      const url = form.attr("action");

      $.post(url, formData).then(data => {
        if (data.success) {
          MicroModal.close("status");
          $(".modal__zoom")
            .children()
            .remove();
          this.loadSummary();
        } else {
          $(".flashes-error").fadeIn(500);
        }
      });
    });
    form.on("reset", () => {
      $("body").removeClass("modal-is-visible");
      $("#status").removeClass("is-open");
      $(".modal__zoom")
        .children()
        .remove();
      MicroModal.close();
    });
    stringsCollectionField();
  };

  openSituation3MonthsModal = () => {
    const template = $("[data-modal-template='edit-situation-3-months']");

    MicroModal.show("status", {
      ...modalOptions,
      onClose: el => {
        clearModal($(el));
      },
    });

    $("#status [data-modal-content]").html(template.html());

    const form = $("#status [data-modal-content]").find(
      '[name="situation_at_3_months"]'
    );
    customSelect(form.find("select"));

    const studentId = this.container.data("student-id");
    $("[name='situation_at_3_months[studentId]']", form).val(studentId);
    const situationSelect = form.find(
      '[name="situation_at_3_months[situation]"]'
    );
    const resultSelect = form.find(
      '[name="situation_at_3_months[situationResult]"]'
    );

    const allOptions = resultSelect.find("option").clone();
    situationSelect.on("change", evt =>
      handleSituationSelectChange(evt, allOptions, resultSelect)
    );

    form.on("submit", e => {
      e.preventDefault();
      const data = form.serializeArray();
      const formData = {};

      data.forEach(item => (formData[item.name] = item.value));

      const url = form.attr("action");

      $.post(url, formData).then(data => {
        if (data.success) {
          MicroModal.close("status");
          $(".modal__zoom")
            .children()
            .remove();
          this.loadSummary();
        } else {
          $(".flashes-error").fadeIn(500);
        }
      });
    });
    form.on("reset", () => {
      $("body").removeClass("modal-is-visible");
      $("#status").removeClass("is-open");
      $(".modal__zoom")
        .children()
        .remove();
      MicroModal.close();
    });
    stringsCollectionField();
  };

  openSituation6MonthsModal = () => {
    const template = $("[data-modal-template='edit-situation-6-months']");
    MicroModal.show("status", {
      ...modalOptions,
      onClose: el => {
        clearModal($(el));
      },
    });
    $("#status [data-modal-content]").html(template.html());
    const form = $("#status [data-modal-content]").find("form");
    customSelect(form.find("select"));

    const studentId = this.container.data("student-id");
    $("[name='situation_at_6_months[studentId]']", form).val(studentId);

    const situationSelect = form.find(
      '[name="situation_at_6_months[situation]"]'
    );
    const resultSelect = form.find(
      '[name="situation_at_6_months[situationResult]"]'
    );

    const allOptions = resultSelect.find("option").clone();
    situationSelect.on("change", evt =>
      handleSituationSelectChange(evt, allOptions, resultSelect)
    );

    form.on("submit", e => {
      e.preventDefault();
      const data = form.serializeArray();
      const formData = {};

      data.forEach(item => (formData[item.name] = item.value));

      const url = form.attr("action");

      $.post(url, formData).then(data => {
        if (data.success) {
          MicroModal.close("status");
          $(".modal__zoom")
            .children()
            .remove();
          this.loadSummary();
        } else {
          $(".flashes-error").fadeIn(500);
        }
      });
    });
    form.on("reset", () => {
      $("body").removeClass("modal-is-visible");
      $("#status").removeClass("is-open");
      $(".modal__zoom")
        .children()
        .remove();
      MicroModal.close();
    });
    stringsCollectionField();
  };

  openSummary = () => {
    $(".student__sheet__row")
      .not(this.row)
      .removeClass("is-open");

    if (this.isOpen()) {
      this.row.removeClass("is-open");
    } else {
      if (0 === this.summaryContainer.children().length) {
        this.setIsLoading(true);
        this.loadSummary(() => {
          this.row.addClass("is-open");
          this.setIsLoading(false);
        });
      } else {
        this.row.addClass("is-open");
      }
    }
  };

  clearSummary = () => {
    this.summaryContainer.html("");
  };

  setIsLoading = isLoading => {
    isLoading ? this.row.addClass("loading") : this.row.removeClass("loading");
  };

  loadSummary = callback => {
    this.summaryContainer
      .find("[data-action='modify-orientation-proposed']")
      .off("click", this.openProjectOrientationModal);
    this.summaryContainer
      .find("[data-action='integrated-training']")
      .off("click", this.openIntegratedTrainingModal);
    this.summaryContainer
      .find("[data-action='add-pmsmp']")
      .off("click", this.openPMSMPModal);
    this.summaryContainer
      .find("[data-action='edit-situation-3-months']")
      .off("click", this.openSituation3MonthsModal);
    this.summaryContainer
      .find("[data-action='edit-situation-6-months']")
      .off("click", this.openSituation6MonthsModal);
    this.summaryContainer
      .find("[data-action='delete-sheet']")
      .off("click", this.openDeleteModal);
    this.summaryContainer
      .find("[data-action='modify-sheet']")
      .off("click", this.openEditModal);
    this.summaryContainer
      .find('[data-action="edit-status"]')
      .off("click", this.handleSummaryEditStatus);

    $.get(`/me/student-summary/${this.studentId}.html`).then(html => {
      this.summaryContainer.html(html);
      this.summaryContainer
        .find('[data-action="modify-orientation-proposed"]')
        .on("click", this.openProjectOrientationModal);
      this.summaryContainer
        .find('[data-action="integrated-training"]')
        .on("click", this.openIntegratedTrainingModal);
      this.summaryContainer
        .find('[data-action="add-pmsmp"]')
        .on("click", this.openPMSMPModal);
      this.summaryContainer
        .find('[data-action="edit-situation-3-months"]')
        .on("click", this.openSituation3MonthsModal);
      this.summaryContainer
        .find('[data-action="edit-situation-6-months"]')
        .on("click", this.openSituation6MonthsModal);
      this.summaryContainer
        .find('[data-action="edit-status"]')
        .on("click", this.handleSummaryEditStatus);
      this.summaryContainer
        .find("[data-action='delete-sheet']")
        .on("click", this.openDeleteModal);
      this.summaryContainer
        .find("[data-action='modify-sheet']")
        .on("click", this.openEditModal);

      if ("function" === typeof callback) {
        callback();
      }
    });
  };

  openEditModal = () => {
    this.editModalButton.addClass("loading");

    $.get(`/me/student/${this.studentId}.json`).then(data => {
      const studentForm = new StudentForm();
      studentForm.hydrate(data);
      studentForm.onOpen = () => this.editModalButton.removeClass("loading");
      studentForm.open();
    });
  };

  isOpen = () => {
    return this.row.hasClass("is-open");
  };

  handleSummaryEditStatus = e => {
    const statusId = $(e.target).data("status");
    $.get(`/me/student/${this.studentId}/status-data.json`).then(data => {
      this.openStatusModal(statusId.toString(), true, data);
    });
  };

  initStatusModal = () => {
    // STATUS MODAL DROPDOWN LISTENER
    // on garde l'ancienne valeur pour y revenir si on ne submit pas
    this.currentStatusSelectVal = this.container
      .find("[status-select-dropdown]")
      .get(0).selectedOptions[0].value;

    this.statusSelect.on("change", () => {
      const statusId = this.statusSelect.get(0).selectedOptions[0].value;
      this.openStatusModal(statusId);
    });
  };

  fillStatusForm = (statusId, data) => {
    Object.keys(data).forEach(key => {
      const selector = `[name="${key}"]`;
      const html = $("#status [data-modal-content]");
      const element = html.find(selector);
      const value = data[key];

      if ("radio" === element.attr("type")) {
        element.prop("checked", 1 === value);
      } else if (key.includes("__name__")) {
        Object.keys(value).forEach(key => {
          const item = value[key];
          element.val(item);
          element.next().trigger("click");
        });
      } else if (key.includes("__collection__")) {
        value.map(collection => {
          Object.keys(collection).forEach((name, index) => {
            const collectionElement = html.find(`[name="${name}"]`);
            collectionElement.val(collection[name]);

            if (index === Object.keys(collection).length - 1) {
              collectionElement
                .parents('[data-class="companies_collection_row"]')
                .find('[data-role="add_companies_collection_widget"]')
                .trigger("click");
            }
          });
        });
      } else {
        element.val(value);
      }
    });
  };

  // STATUS POPINS
  openStatusModal = (statusId, force = false, data = null) => {
    let hasChangedStatus = false;
    // si la valeur n'a pas changé, on arrête tout
    if (statusId === this.currentStatusSelectVal && !force) {
      return;
    }
    // Déclarer un template null
    let template = null;

    switch (statusId) {
      case "3":
        template = $('[data-modal-template="parcours-integration"]');
        break;

      case "4":
        template = $('[data-modal-template="stop-course"]');
        break;

      case "5":
        template = $('[data-modal-template="parcours-prepa"]');
        break;

      default:
        console.warn("modalType not found !");
        return;
    }

    // Et n'append  qu'une seule fois le template choisi
    const modalContainer = $("#status [data-modal-content]");
    modalContainer.html(template.html());

    stringsCollectionField();
    companiesCollectionField();

    if (data) {
      this.fillStatusForm(statusId, data);
    }

    const cstmSelects = customSelect("#status select");

    const integrationDateSelects = {
      day: modalContainer.find(
        '[name="student_status_integration[integratedAt][day]"]'
      ),
      month: modalContainer.find(
        '[name="student_status_integration[integratedAt][month]"]'
      ),
      year: modalContainer.find(
        '[name="student_status_integration[integratedAt][year]"]'
      ),
    };

    const endPrepaDate = {
      day: modalContainer.find(
        '[name="student_status_end_prepa[endedPrepaAt][day]"]'
      ),
      month: modalContainer.find(
        '[name="student_status_end_prepa[endedPrepaAt][month]"]'
      ),
      year: modalContainer.find(
        '[name="student_status_end_prepa[endedPrepaAt][year]"]'
      ),
    };

    cstmSelects.forEach(item => {
      if (item.select.name.startsWith("student_status_integration")) {
        $(item.select).on("change", () => {
          modalContainer.find(".form-errors").remove();
        });
      }
    });

    MicroModal.show("status", {
      ...modalOptions,
      onClose: el => {
        clearModal($(el));

        // si le statut n'a pas changé, on reset le select
        // au statut précédent
        if (!hasChangedStatus) {
          this.statusCustomSelect.value = this.currentStatusSelectVal;
        }
      },
    });

    const form = modalContainer.find("form");
    form
      .find("[name='student_status_integration[studentActionId]']")
      .val(this.studentActionId);
    form
      .find("[name='student_status_dropout[studentActionId]']")
      .val(this.studentActionId);
    form
      .find("[name='student_status_end_prepa[studentActionId]")
      .val(this.studentActionId);

    const inputRadioObjectif = form.find(
      'input:radio[name="student_status_end_prepa[achievedGoal]"]'
    );

    form.on("reset", () => {
      MicroModal.close("status");
    });

    const meetDate = this.studentMetAt;
    const integrationDate = this.studentIntegratedAt;

    form.on("submit", e => {
      e.preventDefault();
      const submitButton = form.find('[type="submit"]');
      const data = form.serializeArray();
      const formData = {};

      if (inputRadioObjectif.length) {
        if (inputRadioObjectif.prop("checked") !== true) {
          $(".error__message-hours").fadeIn(500);
          $("[error-message-close]").on("click", function() {
            $(".error__message-hours").fadeOut(500);
          });
          return;
        }
      }

      if (integrationDateSelects.day.length > 0) {
        const date = moment(
          `${integrationDateSelects.year.val()}-${integrationDateSelects.month.val()}-${integrationDateSelects.day.val()}`,
          "YYYY-MM-DD"
        );

        if (!date.isAfter(meetDate)) {
          handleFormErrorsOnSubmit({
            responseJSON: {
              errors: [
                `La date d'intégration doit être postérieure à la date de rencontre (${meetDate.format(
                  "DD/MM/YYYY"
                )})`,
              ],
            },
          });

          return;
        }
      }

      if (endPrepaDate.day.length > 0) {
        const endDate = moment(
          `${endPrepaDate.year.val()}-${endPrepaDate.month.val()}-${endPrepaDate.day.val()}`,
          "YYYY-M-D"
        );
        if (!endDate.isAfter(integrationDate)) {
          handleFormErrorsOnSubmit({
            responseJSON: {
              errors: [
                `La date de fin de parcours doit être postérieure à la date d'intégration (${integrationDate.format(
                  "DD/MM/YYYY"
                )})`,
              ],
            },
          });

          return;
        }
      }

      data.forEach(item => (formData[item.name] = item.value));

      const url = form.attr("action");

      submitButton.prop("disabled", true).addClass("loading");
      $(".flashes-error").fadeOut();

      $.post(url, formData)
        .then(data => {
          submitButton.prop("disabled", false).removeClass("loading");
          if (data.success) {
            // le statut a changé, on le note pour
            // ne pas reset le select à la fermeture de la modal
            hasChangedStatus = true;
            this.currentStatusSelectVal = statusId;

            if (data.hasSentCertificateEnded) {
              openFlash("send-certificate-ended-message");
            }

            if (data.hasSentCertificateStarted) {
              openFlash("send-certificate-started-message");
            }

            this.refresh();

            // update le résumé s'il est ouvert
            // sinon on le vide pour qu'il soit rechargé la prochaine fois
            this.isOpen() ? this.loadSummary() : this.clearSummary();

            MicroModal.close("status");
            $("#status [data-modal-content]")
              .children()
              .remove();
            $(window).trigger(EVENT_STATUS_UPDATED);
          } else {
            $(".flashes-error").fadeIn(500);
          }
        })
        .fail(err => {
          submitButton.prop("disabled", false).removeClass("loading");
          handleFormErrorsOnSubmit(err);
        });
    });

    form.on("reset", () => {
      MicroModal.close("status");
    });

    // Conditional fields in Status Modal
    form.find("[condition-section]").hide();

    const dropoutReasonSelect = form.find(
      "#student_status_dropout_dropoutReason"
    );
    const hasContactSelect = form.find(
      "#student_status_end_prepa_endPrepaHasContract"
    );
    const situationSelect = form.find(
      "#student_status_end_prepa_endPrepaSituation"
    );

    const updateStripedRows = () => {
      modalContainer
        .find(".modal__section--bg-grey")
        .removeClass("modal__section--bg-grey");
      modalContainer
        .find(".modal__section:visible:odd:not(:nth-last-child(2))")
        .addClass("modal__section--bg-grey");
    };

    const updateDropoutSections = () => {
      const fieldValue = dropoutReasonSelect.val();
      const fieldCode = dropoutReasonSelect
        .find(`option[value="${fieldValue}"]`)
        .data("code");

      $("[dropout-reason-other").hide();
      $("[dropout-reason-other").removeClass("required");
      $("[dropout-reason-other")
        .find("input")
        .prop("required", false)
        .val("");

      switch (fieldCode) {
        case "dropout_reason_entree_en_apprentissage":
          $("[condition-section]").hide();
          $("[dropout-reason-training").show();
          break;

        case "dropout_reason_entree_en_emploi":
          $("[condition-section]").hide();
          $("[dropout-reason-company").show();
          break;

        case "dropout_reason_autre":
          $("[condition-section]").hide();
          $("[dropout-reason-other").show();
          $("[dropout-reason-other").addClass("required");
          $("[dropout-reason-other")
            .find("input")
            .prop("required", true);
          break;
      }
      updateStripedRows();
    };

    const updateEndPrepaSections = () => {
      const fieldValue = hasContactSelect.val();
      switch (fieldValue) {
        case "1":
          $("[condition-section]").hide();
          $("[contract-training").show();
          $("[contract-company").show();
          $("[contract-signed").show();
          $("[situation]")
            .find("select")
            .prop("required", false);
          break;

        case "0":
          $("[condition-section]").hide();
          $("[situation]").show();
          $("[situation]")
            .find("select")
            .prop("required", true);
          break;
      }

      handleSituationSelect();
      updateStripedRows();
    };

    const handleSituationSelect = () => {
      const fieldValue = situationSelect.val();
      const fieldCode = situationSelect
        .find(`option[value="${fieldValue}"]`)
        .data("code");

      $("[situation-to-define]").hide();
      $("[situation-to-define]").prop("required", false);
      $("[situation-to-define]").val("");

      if ("student_stuation_autres" === fieldCode) {
        $("[situation-to-define]").show();
        $("[situation-to-define]").prop("required", true);
      }
      updateStripedRows();
    };

    updateDropoutSections();
    updateEndPrepaSections();
    updateStripedRows();
    handleSituationSelect();

    dropoutReasonSelect.on("change", updateDropoutSections);
    hasContactSelect.on("change", updateEndPrepaSections);
    situationSelect.on("change", handleSituationSelect);
  };
}

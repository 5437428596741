import "./utils/compat";
import "promise-polyfill/src/polyfill";
import $ from "jquery";
import stringsCollectionField from "./stringsCollectionField";
import Dashboard from "./Dashboard";
import { 
  EVENT_STUDENT_UPDATED, 
  EVENT_STUDENT_CREATED, 
  EVENT_STUDENT_DELETED,
  EVENT_STUDENT_DRAFT_CREATED
} from "./events";
import FlashMessage, { openFlash } from "./utils/flashMessage";
import companiesCollectionField from "./companiesCollectionField";


$('[data-role="show_password"]').click(function() {
  $(this).toggleClass("is-visible");
  const field = $(this).parent().find('input');
  
  if ("password" === field.attr("type")) {
    field.attr("type", "text");
  }
  else {
    field.attr("type", "password");
  }
});

$(document).on("click", "[data-disabled]", e => e.preventDefault());

const dashboardPage = $('[data-page="dashboard"]');

if (dashboardPage.length > 0) {
  stringsCollectionField();
  companiesCollectionField();
  new Dashboard(dashboardPage);
}

$("[data-choice-school-input]").on("click", function() {
  $(".school__change__row").removeClass("school__change__row--current-school");
  $(this).parent().addClass("school__change__row--current-school");
});

$("[data-choice-school-cancel]").on("click", function(e) {
  e.preventDefault();
  $(".school__change__row").removeClass("school__change__row--current-school");
});

(new FlashMessage()).successMessage().errorMessage();

$(window).on([
  EVENT_STUDENT_UPDATED, 
  EVENT_STUDENT_CREATED, 
  EVENT_STUDENT_DELETED,
  EVENT_STUDENT_DRAFT_CREATED,
].join(" "), (e) => {
  const eventName = e.type;

  let id = null;
  
  switch (eventName) {
    case EVENT_STUDENT_DELETED:
      id = "student-deleted-message";
      break;
    case EVENT_STUDENT_CREATED:
      id = "student-created-message";
      break;
    case EVENT_STUDENT_DRAFT_CREATED:
      id = "student-draft-created-message";
      break;
    case EVENT_STUDENT_UPDATED:
      id = "student-updated-message";
      break;
  }

  if (id) {
    openFlash(id);
  }
});

import $ from "jquery";
import StudentRow from "./StudentRow";
import {
  EVENT_STUDENT_DRAFT_CREATED,
  EVENT_STUDENT_CREATED,
  EVENT_STUDENT_DELETED,
} from "./events";

export default class StudentList {
  static offset = 20;

  container = null;
  rows = [];
  currentPage = 1;
  spinner = null;
  emptyMessage = null;
  totalCount = null;
  preventFetching = false;
  statuses = [];
  filtersForm = null;
  filtersPopin = null;
  needsReset = false;
  prevButton = null;
  nextButton = null;
  paginationCurrent = null;
  paginationTotal = null;

  constructor(container, filtersForm, filtersPopin) {
    this.container = container;
    this.spinner = this.container.find(".school__students__list__spinner");
    this.emptyMessage = this.container.find(".school__students__list__empty");
    this.prevButton = this.container.find(
      '[data-action="students-list-previous"]'
    );
    this.nextButton = this.container.find('[data-action="students-list-next"]');
    this.paginationCurrent = this.container.find(
      '[data-id="pagination-current"]'
    );
    this.paginationTotal = this.container.find('[data-id="pagination-total"]');

    this.container.find(".student__sheet__item").each((index, item) => {
      this.rows.push(new StudentRow($(item), this));
    });

    this.filtersForm = filtersForm;
    this.filtersForm.on("submit", this.handleFiltersForm);
    this.filtersPopin = filtersPopin;

    this.fetchData();

    this.prevButton.on("click", this.prevPage);
    this.nextButton.on("click", this.nextPage);

    $(window).on(
      [
        EVENT_STUDENT_DRAFT_CREATED,
        EVENT_STUDENT_CREATED,
        EVENT_STUDENT_DELETED,
      ].join(" "),
      () => {
        this.refresh();
      }
    );
  }

  fetchData = () => {
    const offset = (this.currentPage - 1) * StudentList.offset;
    this.preventFetching = true;
    this.hideEmptyMessage();
    this.showSpinner();

    let query = "";

    if (this.statuses.length > 0) {
      query = `?statuses=${this.statuses.join(",")}`;
    }

    this.clearList();
    $.get(`/me/students/${offset}.json${query}`).then(this.insertData);
    // .catch(this.handleFetchError)
  };

  handleFetchError = err => {
    this.hideSpinner();
    this.preventFetching = true;
    console.error(err);
    window.alert(
      "Une erreur est survenue, merci de réessayer ultérieurement ou de contacter un administrateur."
    );
  };

  insertData = data => {
    this.preventFetching = false;
    this.hideSpinner();

    if (data.students_count) {
      this.totalCount = data.students_count;
      this.pageCount = Math.ceil(this.totalCount / StudentList.offset);
    }

    this.updatePagination();

    if (Array.isArray(data.templates) && data.templates.length > 0) {
      data.templates.forEach(item => {
        const id = $(item).data("student-id");
        if (!id) {
          return;
        }

        $(item).insertBefore(this.spinner);
        const row = this.container.find(`[data-student-id="${id}"]`);
        this.rows.push(new StudentRow(row, this));
      });
    } else {
      this.showEmptyMessage();
      if (this.statuses.length > 0) {
        this.needsReset = true;
      }
    }
  };

  showSpinner = () => {
    this.spinner.show();
  };

  hideSpinner = () => {
    this.spinner.hide();
  };

  showEmptyMessage = () => {
    this.emptyMessage.show();
  };

  hideEmptyMessage = () => {
    this.emptyMessage.hide();
  };

  prevPage = () => {
    if (this.currentPage <= 1) {
      return;
    }

    this.currentPage = this.currentPage - 1;
    this.scrollToTop();
    this.fetchData();
  };

  nextPage = () => {
    if (this.currentPage === this.pageCount) {
      return;
    }

    this.currentPage = this.currentPage + 1;
    this.scrollToTop();
    this.fetchData();
  };

  updatePagination = () => {
    this.prevButton.prop("disabled", 1 === this.currentPage);
    this.nextButton.prop("disabled", this.currentPage === this.pageCount);
    this.paginationCurrent.text(this.currentPage);
    this.paginationTotal.text(this.pageCount);
  };

  handleScroll = () => {
    if (this.needsReset) {
      return;
    }

    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.fetchData();
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: this.container.offset().top,
    });
  };

  handleFiltersForm = e => {
    e.preventDefault();

    this.needsReset = false;
    this.filtersPopin.hide();

    this.useFiltersFormData();

    this.reset();
    this.fetchData();
  };

  useFiltersFormData = () => {
    const data = this.filtersForm.serializeArray();

    const filters = data
      .filter(item => "form[statusFilter][]" === item.name)
      .map(item => item.value);

    this.statuses = filters;
  };

  clearList = () => {
    this.container.find(".student__sheet__item").remove();
    this.rows = [];
  };

  reset = () => {
    this.needsReset = false;
    this.currentPage = 1;
    this.totalCount = null;
    this.clearList();
  };

  refresh = () => {
    this.fetchData();
  };
}

import $ from "jquery";

export function openFlash(id) {
  const flashMessage = $('#' + id);
  flashMessage.fadeIn(500);
  setTimeout(function() {
    $(".success__message").fadeOut();
  }, 5000);
}

export default class FlashMessage {
  constructor(message = null) {
    this.message = message;
    this.container = $('[data-flash="symfony"]');
  }

  setMessage(message) {
    this.message = message;
  }

  successMessage() {
    if (this.message) {
      this.container.html(`<p class="flash-success">${this.message}</p>`);
    }

    const flash = $(".flashes-success", this.container).fadeIn(500);
    

    if (flash.length > 0) {
      window.setTimeout(() => {
        flash.fadeOut(500, () => {
          flash.remove();
          this.message = null;
        });
      }, 5000);
    }

    return this;
  }

  errorMessage() {
    if (this.message) {
      this.container.html(`<p class="flash-error">${this.message}</p>`);
    }

    const flash = $(".flashes-error", this.container).fadeIn(500);

    if (flash.length > 0) {
      window.setTimeout(() => {
        flash.fadeOut(500, () => {
          flash.remove();
          this.message = null;
        });
      }, 5000);
    }

    return this;
  }
}

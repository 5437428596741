import $ from "jquery";
import shareDocumentModal from "./shareDocumentModal";
import MicroModal from "micromodal";
import { modalOptions, clearModal } from "./utils/modal";

export default class DocumentBase {
  static offset = 5;

  container = null;
  rows = [];
  currentPage = 1;
  spinner = null;
  emptyMessage = null;
  totalCount = null;
  preventFetching = false;
  filtersForm = null;
  filtersPopin = null;
  prevButton = null;
  nextButton = null;
  paginationCurrent = null;
  paginationTotal = null;
  shareButton = null;

  constructor(container) {
    this.container = container;
    this.spinner = this.container.find(".document__list__spinner");
    this.emptyMessage = this.container.find(".document__list__empty");
    this.prevButton = this.container.find('[data-action="document-list-previous"]');
    this.nextButton = this.container.find('[data-action="document-list-next"]');
    this.paginationCurrent = this.container.find('[data-id="pagination-current"]');
    this.paginationTotal = this.container.find('[data-id="pagination-total"]');

    this.container.find('.document__row').each((index, item) => {
      this.rows.push(($(item), this));
    });

    this.fetchData();

    this.prevButton.on("click", this.prevPage);
    this.nextButton.on("click", this.nextPage);
  }

  openShareDocumentModal = (docId) => {
    shareDocumentModal();
    const form = $("#status [data-modal-content]").find("form");
    form.find("[name='share_document[documentId]']").val(docId);

    form.on("submit", (e) => {
      e.preventDefault();
      const data = form.serializeArray();
      const formData = {};

      data.forEach(item => formData[item.name] = item.value);
      
      const url = form.attr('action');

      $.post(url, formData).then(data => {
        if (data.success) {
          MicroModal.close('status');
          $(".modal__zoom").children().remove();
          //@TODO ADD EVENT TO REFRESH DATA
          $(".success__message").fadeIn(500);
          setTimeout(function() {
            $(".success__message").fadeOut();
          }, 5000);
        }
      });
    });
  }

  fetchData = () => {
    const offset = (this.currentPage - 1) * DocumentBase.offset;
    this.preventFetching = true;
    this.hideEmptyMessage();
    this.showSpinner();

    this.clearList();

    $.get(`/me/documents/${offset}.json`)
      .then(this.insertData)
      // .catch(this.handleFetchError)
    ;
  }

  handleFetchError = (err) => {
    this.hideSpinner();
    this.preventFetching = true;
    console.error(err);
    window.alert("Une erreur est survenue, merci de réessayer ultérieurement ou de contacter un administrateur.");
  }

  insertData = (data) => {
    this.preventFetching = false;
    this.hideSpinner();
    
    if (data.documents_count) {
      this.totalCount = data.documents_count;
      this.pageCount = Math.ceil(this.totalCount / DocumentBase.offset);
    }
    
    this.updatePagination();

    if (Array.isArray(data.documents) && data.documents.length > 0) {
      data.documents.forEach(item => {
        const id = $(item).data("document-id");
        if (!id) {
          return;
        }

        $(item).insertBefore(this.spinner);
        const row = this.container.find(`[data-document-id="${id}"]`);
        this.rows.push(row);

        row.find('[data-action="share-button"]').on("click", () => {
          this.openShareDocumentModal(id);
        });
      });
    }
    else {
      this.showEmptyMessage();
    }
  }

  showSpinner = () => {
    this.spinner.show();
  }

  hideSpinner = () => {
    this.spinner.hide();
  }

  showEmptyMessage = () => {
    this.emptyMessage.show();
  }

  hideEmptyMessage = () => {
    this.emptyMessage.hide();
  }

  prevPage = () => {
    if (this.currentPage <= 1) {
      return;
    }

    this.currentPage = this.currentPage - 1;
    this.fetchData();
  }

  nextPage = () => {
    if (this.currentPage === this.pageCount) {
      return;
    }

    this.currentPage = this.currentPage + 1;
    this.fetchData();
  }

  updatePagination = () => {
    this.prevButton.prop("disabled", 1 === this.currentPage);
    this.nextButton.prop("disabled", this.currentPage === this.pageCount);
    this.paginationCurrent.text(this.currentPage);
    this.paginationTotal.text(this.pageCount);
  }
  clearList = () => {
    this.container.find('.document__row').remove();
    this.rows = [];
  }
}



import $ from "jquery";
import { 
  EVENT_STUDENT_UPDATED, 
  EVENT_STATUS_UPDATED, 
  EVENT_STUDENT_CREATED,
  EVENT_STUDENT_DRAFT_CREATED
} from "./events";

export default class KeyFigures {
  container;

  constructor(container) {
    this.container = container;
    $(window).on([
      EVENT_STUDENT_DRAFT_CREATED,
      EVENT_STUDENT_CREATED, 
      EVENT_STUDENT_UPDATED,
      EVENT_STATUS_UPDATED,
    ].join(" "), this.refresh);
  }

  refresh = () => {
    const html = this.container.html();
    const figures = this.container.find(".school__keyfigures__number");
    figures.html("");
    figures.addClass("loading");

    $.get("/me/key-figures.json", data => {
      if (data.template) {
        this.container.html(data.template);
      }
      else {
        this.container.html(html);
      }

      figures.removeClass("loading");
    });
  }
}

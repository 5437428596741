import $ from "jquery";

export default function stringsCollectionField() {
  $('[data-role="add-strings-collection-widget"]').click(function () {
    const row = $(this).parents('[data-class="strings_collection_row"]');
    const list = $($(this).attr('data-list-selector'), row);
    const textField = $('[data-role="collection-prototype"]', row);

    // Try to find the counter of the list or use the length of the list
    const counter = parseInt(list.attr('data-widget-counter'), 10) || list.children().length;

    const newName = textField.attr('name').replace(/__name__/g, counter);
    const newValue = textField.val();
    if (!newValue) {
      return;
    }
    const newWidget = `
      <input type="hidden" name="${newName}" value="${newValue}" />
      <span>${textField.val()}</span>
      <button type="button" class="close--icon" data-role="remove-strings-collection-widget"><img src="../img/icons/icon_close_grey.svg" /></button>
    `;
    
    list.attr('data-widget-counter', (counter + 1));

    // create a new list element and add it to the list
    const newElem = $(list.attr('data-widget-tags')).html(newWidget);
    newElem.appendTo(list);
    textField.val("");
    removeStringsCollectionWidget();
  });

  removeStringsCollectionWidget();
}

function removeStringsCollectionWidget() {
  $('[data-role="remove-strings-collection-widget"]').click(function () {
    const list = $(this).parents('ul');
    const counter = parseInt(list.attr('data-widget-counter'), 10) || list.children().length;
    list.attr('data-widget-counter', (counter - 1));

    $(this).parent().remove();
  });
}

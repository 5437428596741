import $ from "jquery";

export default function handleFormErrorsOnSubmit(err) {
  const response = err.responseJSON;

  let errorsMsg = response && response.errors;

  if (errorsMsg.hasOwnProperty("errors")) errorsMsg = errorsMsg.errors;

  if (errorsMsg && Array.isArray(errorsMsg)) {
    let message = "";
    errorsMsg.map(msg => (message += `<li>${msg}</li>`));
    $(".flashes-error")
      .html(`<ul>${message}</ul>`)
      .fadeIn(400);
    window.setTimeout(() => {
      $(".flashes-error").fadeOut(400);
    }, 5000);
  } else {
    $(".flashes-error")
      .html(`Une erreur s’est produite lors de la soumission.`)
      .fadeIn(400);
  }
}

import StudentList from "./StudentsList";
import FiltersPopin from "./FiltersPopin";
import { initModal } from "./utils/modal";
import MicroModal from "micromodal";
import { modalOptions, clearModal } from "./utils/modal";
import $ from "jquery";
import StudentForm from "./StudentForm";
import KeyFigures from "./KeyFigures";
import DocumentBase from "./DocumentBase";
import getParameterByName from "./utils/getParameterByName";

export default class Dashboard {
  container;
  studentsList;
  keyFigures;
  documentsBase;
  documentsList;
  searchForm;
  changeSchoolButton;

  constructor(container) {
    this.container = container;
    
    initModal();
    this.initStudentsList();
    this.initHeader();
    this.initDocumentsList();
    this.handleShowSearchButtons();

    this.keyFigures = new KeyFigures($("#school__keyfigures"));

    this.changeSchoolButton = $('[data-open-school-choice]');

    $('#add-student-button').on("click", this.openCreateStudentModal);

    this.changeSchoolButton.on("click", this.openChangeSchoolModal);
  }

  initHeader = () => {
    $("[header-preferences-button]").on('click', function() {
      if ($(this).hasClass("is-active")) {
        $(".header__connect__slide-in").removeClass("is-active");
        $(".header__connect__button").removeClass("is-active");
      }
      else {
        $(".header__connect__button").removeClass("is-active");
        $(".header__connect__slide-in").addClass("is-active");
        $(this).addClass("is-active");
      }
      $(".header__connect__slide-in--docs").hide();
      $(".header__connect__slide-in--password").show();
    });

    $("[base-documents-button]").on('click', function() {
      if ($(this).hasClass("is-active")) {
        $(".header__connect__slide-in").removeClass("is-active");
        $(".header__connect__button").removeClass("is-active");
      }
      else {
        $(".header__connect__button").removeClass("is-active");
        $(".header__connect__slide-in").addClass("is-active");
        $(this).addClass("is-active");
      }
      $(".header__connect__slide-in--password").hide();
      $(".header__connect__slide-in--docs").show();
    });
        
    $("[button-close-slidein]").on('click', function() {
      $(".header__connect__slide-in").removeClass("is-active");
      $(".header__connect__button").removeClass("is-active");
      $(".header__connect__slide-in_password").hide();
      $(".header__connect__slide-in_docs").hide();
    });
  }

  initStudentsList = () => {
    const listContainer = $("#school__students__list");
    const filtersForm = $('form[name="filter-form"]');
    const filtersPopin = new FiltersPopin(
      $('[data-action="filter-popin-button"]'), 
      $('[data-role="filter-popin"]')
    );

    this.studentList = new StudentList(
      listContainer,
      filtersForm,
      filtersPopin
    );

    $('[type="reset"]', filtersForm).on("click", () => {
      filtersForm[0].reset();
      filtersForm.submit();
    });
  }

  initDocumentsList = () => {
    const listContainer = $("#documents__list");
    this.documentsList = new DocumentBase(listContainer);
  }

  openCreateStudentModal = () => {
    const form = new StudentForm();

    form.open();
  }

  openChangeSchoolModal = (e) => {
    e.preventDefault();

    const curSchoolId = this.changeSchoolButton.attr('data-school-id');
    
    const template = $('[data-modal-template="change-school"]').html();
    const modal = $("#zoom [data-modal-content]");
    modal.append(template);

    $('[data-choice-school-input]').each(function() {
      if (curSchoolId ===  $(this).val()) {
        $(this).parent().addClass("school__change__row--current-school");
      }
    });

    $("[data-choice-school-input]").on("click", function() {
      $(".school__change__row").removeClass("school__change__row--current-school");
      $(this).parent().addClass("school__change__row--current-school");
    });
  
    MicroModal.show('zoom', {
      ...modalOptions,
      onClose: el => {
        clearModal($(el));
      },
    });
  }

  handleShowSearchButtons() {
    const searchQuery = getParameterByName("search");

    if (!searchQuery) return;

    const hideSubmit = () => {
      $('[data-search="cancel"]').fadeIn();
      $('[data-search="submit"]').fadeOut();
    };

    const showSubmit = () => {
      $('[data-search="submit"]').fadeIn();
      $('[data-search="cancel"]').fadeOut();
    };

    hideSubmit();

    $('[data-search="input"]').on('keyup', function() {
      const term = $(this).val();
      if (term === searchQuery) hideSubmit();
      else showSubmit();
    });
  }
}

import $ from "jquery";
import MicroModal from "micromodal";
import { modalOptions, clearModal } from "./utils/modal";


export default function openConfirmValidationModal() {
  const template = $("[data-modal-template='validate-sheet']");
    
  MicroModal.show('status', {
    ...modalOptions,
    onClose: el => {
      clearModal($(el));
    },
  });
  $("#status [data-modal-content]").html(template.html());
}

  

import $ from "jquery";

export default function companiesCollectionField() {
  $('[data-role="add_companies_collection_widget"]').click(function () {
    const row = $(this).parents('[data-class="companies_collection_row"]');
    const list = $($(this).attr('data-list-selector'), row);

    // get collection's field
    const nameField = $('[data-prototype="companies__name"]', row);
    const cityField = $('[data-prototype="companies__city"]', row);
    const postalCodeField = $('[data-prototype="companies__postal_code"]', row);

    // Try to find the counter of the list or use the length of the list
    const counter = parseInt(list.attr('data-widget-counter'), 10) || list.children().length;

    // replace field's name attribute by the next in the collection
    const newNameName = nameField.attr('name').replace(/__name__/g, counter);
    const newCityName = cityField.attr('name').replace(/__name__/g, counter);
    const newPostalCodeName = postalCodeField.attr('name').replace(/__name__/g, counter);
    
    // if there is no value, the addition is blocked
    if (!nameField.val()) return;

    // construct the addition for the list
    const newWidget = `
      <input type="hidden" name="${newNameName}" value="${nameField.val()}" />
      <input type="hidden" name="${newCityName}" value="${cityField.val()}" />
      <input type="hidden" name="${newPostalCodeName}" value="${postalCodeField.val()}" />
      <span>${nameField.val()} - ${postalCodeField.val()} ${cityField.val()}</span>
      <button type="button" class="close--icon" data-role="remove_companies_collection_widget"><img src="../img/icons/icon_close_grey.svg" /></button>
    `;
    
    list.attr('data-widget-counter', (counter + 1));

    // create a new list element and add it to the list
    const newElem = $(list.attr('data-widget-tags')).html(newWidget);
    newElem.appendTo(list);

    // reset value
    nameField.val("");
    cityField.val("");
    postalCodeField.val("");

    // trigger remove delete
    removeStringsCollectionWidget();
  });

  removeStringsCollectionWidget();
}

function removeStringsCollectionWidget() {
  $('[data-role="remove_companies_collection_widget"]').click(function () {
    const list = $(this).parents('ul');
    const counter = parseInt(list.attr('data-widget-counter'), 10) || list.children().length;
    list.attr('data-widget-counter', (counter - 1));

    $(this).parent().remove();
  });
}

import $ from "jquery";
import MicroModal from "micromodal";
export const modalOptions = {
  awaitCloseAnimation: true,
  disableScroll: true,
  onClose: el => clearModal($(el)),
  // onShow: () => $("body").addClass("modal-is-visible"),
};

export function initModal() {
  MicroModal.init(modalOptions);
}

export function clearModal(element) {
  $("body").removeClass("modal-is-visible");

  const modalType = element.data("modal");
  switch (modalType) {
    case "embed":
      element.find("[data-modal-embed]").attr("src", "");
      break;

    case "status":
      $("#status [data-modal-content]").children().remove();
      $("#status").removeClass("is-open");
      break;

      
    case "zoom":
      $("#zoom [data-modal-content]").children().remove();
      $("#zoom").removeClass("is-open");
      break;

    default:
      console.warn("modalType not found !");
      break;
  }
}
